var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "size-6",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17px",
      "height": "22px",
      "viewBox": "0 0 17 22"
    }
  }, [_c('g', {
    attrs: {
      "stroke": "none",
      "stroke-width": "1",
      "fill": "none",
      "fill-rule": "evenodd",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('g', {
    attrs: {
      "transform": "translate(1, 1)",
      "stroke": "currentColor",
      "stroke-width": "1.5"
    }
  }, [_c('path', {
    attrs: {
      "d": "M15,12 L15,9.375 C15,7.51103897 13.488961,6 11.625,6 L10.125,6 C9.50367966,6 9,5.49632034 9,4.875 L9,3.375 C9,1.51103897 7.48896103,-4.4408921e-16 5.625,-4.4408921e-16 L3.75,-4.4408921e-16 M10.7154901,15.504429 L7.5,9 M7.5,9 L4.5,15.5 M5.5,14 L6.9026453,14 L9.5,14 M6,-4.4408921e-16 L1.125,-4.4408921e-16 C0.504,-4.4408921e-16 0,0.504 0,1.125 L0,18.375 C0,18.996 0.504,19.5 1.125,19.5 L13.875,19.5 C14.496,19.5 15,18.996 15,18.375 L15,9 C15,4.02943725 10.9705627,-4.4408921e-16 6,-4.4408921e-16 Z"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }